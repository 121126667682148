<template>
    <div>
      <PageLoader :storage="appLoading" />
      <ServerError v-if="ServerError" />
      <v-snackbar v-model="showSnackBar" color="#FF6907" right :timeout="timeout">
        <v-layout wrap justify-center>
          <v-flex text-left class="align-self-center">
            <span style="color: #fff">{{ msg }}</span>
          </v-flex>
          <v-flex text-right>
            <v-btn small :ripple="false" text @click="showSnackBar = false">
              <v-icon style="color: #fff">mdi-close</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-snackbar>
      <v-layout wrap justify-center>
        <v-flex xs12>
          <v-layout wrap justify-start pa-5>
            <v-flex xs12>
              <span class="heading">Approve Operations</span>
            </v-flex>
            <v-flex xs12>
              <span style="color: red; font-family: poppinsregular; font-size: 13px">Please select the main criterias for search.</span>
            </v-flex>
            <v-flex xs12>
              <v-layout wrap justify-start pt-2>
                <v-flex xs9 sm7 md6 lg2>
                  <v-layout wrap>
                    <v-flex class="text-left subheading">
                      <v-checkbox v-model="alldata" label="All" @change="checkUpdate()" class="small-checkbox no-space"></v-checkbox>
                    </v-flex>
                    <v-flex class="text-left subheading">
                      <v-checkbox v-model="timeperiod" label="Time Period" :disabled="alldata" class="small-checkbox no-space"></v-checkbox>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
              <v-layout wrap justify-start pb-2>
                <v-flex xs12>
                  <v-layout wrap justify-start>
                    <v-flex xs12 sm4 md4 lg4 class="subheading" v-if="timeperiod">
                      <v-menu ref="menu" v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="fromdate"
                            label="From Date"
                            append-icon="mdi-calendar"
                            readonly
                            outlined
                            dense
                            hide-details="true"
                            v-bind="attrs"
                            v-on="on"
                            :disabled="alldata"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="fromdate"
                          :active-picker.sync="activePicker"
                          :max="new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substring(0, 10)"
                          min="1950-01-01"
                          @change="save"
                        ></v-date-picker>
                      </v-menu>
                    </v-flex>
                    <v-flex xs12 sm4 md4 lg4 pl-lg-2 pl-sm-2 pl-md-2 class="subheading" v-if="timeperiod">
                      <v-menu ref="menu2" v-model="menu2" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="todate"
                            label="To Date"
                            append-icon="mdi-calendar"
                            readonly
                            outlined
                            dense
                            hide-details="true"
                            v-bind="attrs"
                            v-on="on"
                            :disabled="alldata"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="todate"
                          :active-picker.sync="activePickerto"
                          :max="new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substring(0, 10)"
                          min="1950-01-01"
                          @change="saveto"
                        ></v-date-picker>
                      </v-menu>
                    </v-flex>
                    <v-flex xs12 sm4 md4 lg4 pl-lg-2 pl-sm-2 pl-md-2 v-if="timeperiod" align-self-center class="text-center subheading">
                      <v-btn color="success" depressed :disabled="isApproveAllDisabled">
                        <span>Approve All</span>
                      </v-btn>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
              <v-layout wrap justify-start pt-2 v-if="items && items.length > 0">
                <v-flex xs12>
                  <v-card>
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-center tableheading">Released Date</th>
                            <th class="text-center tableheading">District</th>
                            <th class="text-center tableheading">Snake</th>
                            <th class="text-center tableheading">Situation</th>
                            <th class="text-center tableheading">Rescuer</th>
                            <th class="text-center tableheading">Action</th>
                          </tr>
                        </thead>
                        <tbody class="tablesubheading">
                          <tr v-for="(item, index) in items" :key="index" class="text-center">
                            <td>{{ formatDate(item.release.releasedAt) }}</td>
                            <td>
                              <span v-if="item && item.district">{{ item.district }}</span>
                              <span v-else>-</span>
                            </td>
                            <td style="text-transform: capitalize">
                              <span v-if="item && item.reportId.snake && item.reportId.snake.name">{{ item.reportId.snake.name }}</span>
                              <span v-else>-</span>
                            </td>
                            <td>
                              <span v-if="item && item.reportId && item.reportId.situation">{{ item.reportId.situation }}</span>
                              <span v-else>-</span>
                            </td>
                            <td>
                              <span v-if="item && item.rescuer && item.rescuer.name">{{ item.rescuer.name }}</span>
                              <span v-else>-</span>
                            </td>
                            <td>
                              <span>
                                <v-icon small class="ml-5" @click="openviewdetails(item)" color="blue">mdi-eye</v-icon>
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-card>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs12 pb-4 v-if="items && items.length > 0">
          <v-layout wrap justify-center>
            <v-flex xs10>
              <v-pagination
                v-model="currentPage"
                :length="Pagelength"
                :total-visible="7"
                circle
                color="#FF6907"
                small
              ></v-pagination>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs12 v-else>
          <v-layout wrap justify-center pl-5>
            <v-flex xs12 class="text-center">
              <span class="heading">No Data Found!</span>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  export default {
    data() {
      return {
        appLoading: false,
        ServerError: false,
        showSnackBar: false,
        timeout: 5000,
        msg: null,
        count: 20,
        keyword: "",
        currentPage: 1,
        Pagelength: 0,
        userlist: [],
        items: [],
        status: "",
        districts: [
          "All",
          "Palakkad",
          "Alappuzha",
          "Malappuram",
          "Kannur",
          "Wayanadu",
          "Kozhikode",
          "Thrissur",
          "Thiruvananthapuram",
          "Kottayam",
          "Idukki",
          "Ernakulam",
          "Kollam",
          "Pathanamthitta",
          "Kasaragod",
        ],
        alldata: true,
        timeperiod: false,
        bigsnakes: false,
        allsnakes: false,
        users: false,
        activePicker: null,
        fromdate: null,
        menu: false,
        activePickerto: null,
        todate: null,
        menu2: false,
        bigsnakeselect: "",
        allsnakelist: "",
        selecteduser: "",
        bigsnakestypes: [],
        situationlist: [
          "Inside the house",
          "Just outside the building",
          "Natural habitat",
        ],
      };
    },
    computed: {
      isApproveAllDisabled() {
        return !this.fromdate || !this.todate;
      }
    },
    beforeMount() {
      this.getData();
      this.getList();
    },
    watch: {
      currentPage() {
        this.getData();
      },
      count() {
        this.getData();
      },
      fromdate() {
        this.getData();
      },
      todate() {
        this.getData();
      },
      keyword() {
        this.getData();
      },
      menu(val) {
        val && setTimeout(() => (this.activePicker = "YEAR"));
      },
      menu2(val) {
        val && setTimeout(() => (this.activePickerto = "YEAR"));
      },
    },
    methods: {
      openviewdetails(item) {
        this.$router.push({
          path: "/releasedoperationsview",
          query: {
            id: item._id,
          },
        });
      },
      checkUpdate() {
        if (this.alldata == true) {
          this.timeperiod = false;
          this.bigsnakes = false;
          this.allsnakes = false;
          this.users = false;
          this.fromdate = null;
          this.todate = null;
          this.bigsnakeselect = "";
          this.allsnakelist = [];
          this.selecteduser = "";
          this.getData();
        }
      },
      reset() {
        this.alldata == true;
        this.timeperiod = false;
        this.bigsnakes = false;
        this.allsnakes = false;
        this.users = false;
        this.fromdate = null;
        this.todate = null;
        this.bigsnakeselect = [];
        this.allsnakelist = [];
        this.selecteduser = "";
        this.getData();
      },
      save(fromdate) {
        this.$refs.menu.save(fromdate);
      },
      saveto(todate) {
        this.$refs.menu2.save(todate);
      },
      getList() {
        this.appLoading = true;
        axios({
          method: "GET",
          url: "/snake/all/",
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
        })
          .then((response) => {
            if (response.data.status == true) {
              this.snakeitems = response.data.data;
              this.appLoading = false;
            } else {
              this.msg = response.data.msg;
              this.showSnackBar = true;
              this.appLoading = false;
            }
          })
          .catch((err) => {
            this.appLoading = false;
            (this.ServerError = true), console.log(err);
          });
      },
      getData() {
        this.appLoading = true;
        axios({
          method: "GET",
          url: "/operation/getlist",
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
          params: {
            status: "Released",
            keyword: this.keyword,
            count: this.count,
            page: this.currentPage,
            fromDate: this.fromdate,
            toDate: this.todate,
            snake: this.allsnakelist,
            district: this.bigsnakeselect,
            situation: this.selecteduser,
          },
        })
          .then((response) => {
            if (response.data.status == true) {
              this.items = response.data.data;
              this.status = response.data.status;
              this.Pagelength = response.data.pages;
              this.appLoading = false;
            } else {
              this.msg = response.data.msg;
              this.showSnackBar = true;
              this.appLoading = false;
            }
          })
          .catch((err) => {
            this.appLoading = false;
            (this.ServerError = true), console.log(err);
          });
      },
      formatDate(date) {
        if (!date) return "-";
        const [year, month, day] = date.slice(0, 10).split("-");
        return `${day}-${month}-${year}`;
      },
    },
  };
  </script>
<style>
.heading {
  font-family: poppinsregular;
  font-size: 20px;
  font-weight: 900;
}
.subheading {
  font-family: poppinsregular;
  font-size: 15px;
  font-weight: 500;
}
.tableheading {
  font-family: poppinsregular;
  font-size: 15px !important;
  color: black !important;
  text-transform: uppercase !important;
}
.tablesubheading {
  font-family: poppinsregular;
  font-size: 10px;
}
</style>